@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

	@font-face {
		font-family: 'Buenos';
		src: url('fonts/BuenosAires/BuenosAires-Regular.woff2') format('woff2'),
			url('fonts/BuenosAires/BuenosAires-Regular.woff') format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Buenos';
		src: url('fonts/BuenosAires/BuenosAires-Light.woff2') format('woff2'),
			url('fonts/BuenosAires/BuenosAires-Light.woff') format('woff');
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Buenos';
		src: url('fonts/BuenosAires/BuenosAires-Black.woff2') format('woff2'),
			url('fonts/BuenosAires/BuenosAires-Black.woff') format('woff');
		font-weight: 900;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Buenos';
		src: url('fonts/BuenosAires/BuenosAires-Bold.woff2') format('woff2'),
			url('fonts/BuenosAires/BuenosAires-Bold.woff') format('woff');
		font-weight: bold;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Buenos';
		src: url('fonts/BuenosAires/BuenosAires-Book.woff2') format('woff2'),
			url('fonts/BuenosAires/BuenosAires-Book.woff') format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Buenos';
		src: url('fonts/BuenosAires/BuenosAires-SemiBold.woff2') format('woff2'),
			url('fonts/BuenosAires/BuenosAires-SemiBold.woff') format('woff');
		font-weight: 600;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'Buenos';
		src: url('fonts/BuenosAires/BuenosAires-Thin.woff2') format('woff2'),
			url('fonts/BuenosAires/BuenosAires-Thin.woff') format('woff');
		font-weight: 100;
		font-style: normal;
		font-display: swap;
	}

	:root {
		--background: 0deg 0% 100%;
		--foreground: 222deg 47.4% 11.2%;

		--muted: 0 0% 96%;
		--muted-foreground: 215deg 16.3% 46.9%;

		--popover: 0deg 0% 100%;
		--popover-foreground: 222deg 47.4% 11.2%;

		--border: 214deg 31.8% 91.4%;
		--input: 214deg 31.8% 91.4%;

		--card: 0deg 0% 100%;
		--card-foreground: 222deg 47.4% 11.2%;

		--primary: 16deg 100% 64%;
		--primary-foreground: 0deg 0% 100%;
		--primary-light: 16deg 100% 94%;
		--primary-dark: 16deg 100% 58%;

		--secondary: 232deg 23% 48%;
		--secondary-foreground: 0deg 0% 100%;
		--secondary-light: 232deg 23% 84%;
		--secondary-dark: 232deg 23% 34%;

		--accent: 210deg 40% 96.1%;
		--accent-foreground: 222deg 47.4% 11.2%;

		--destructive: 0deg 100% 50%;
		--destructive-foreground: 210deg 40% 98%;

		--ring: 215deg 20.2% 65.1%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 224deg 71% 4%;
		--foreground: 213deg 31% 91%;

		--muted: 223deg 47% 11%;
		--muted-foreground: 215deg 16.3% 56.9%;

		--accent: 216deg 34% 17%;
		--accent-foreground: 210deg 40% 98%;

		--popover: 224deg 71% 4%;
		--popover-foreground: 215deg 20.2% 65.1%;

		--border: 216deg 34% 17%;
		--input: 216deg 34% 17%;

		--card: 224deg 71% 4%;
		--card-foreground: 213deg 31% 91%;

		--primary: 16deg 100% 64%;
		--primary-foreground: 16deg 100% 84%;
		--primary-background: 16deg 100% 58%;

		--secondary: 232deg 23% 48%;
		--secondary-foreground: 232deg 23% 74%;
		--secondary-background: 232deg 23% 34%;

		--destructive: 0deg 63% 31%;
		--destructive-foreground: 210deg 40% 98%;

		--ring: 216deg 34% 17%;

		--radius: 0.5rem;
	}
}

@layer base {
	* {
		@apply border-border;
	}

	body {
		@apply bg-muted text-foreground;
		font-feature-settings:
			"rlig" 1,
			"calt" 1;
	}

	h1 {
		font-size: 2.5rem;
		font-weight: 600;
	}

	h2 {
		font-size: 2.25rem;
		font-weight: 600;
	}

	h3 {
		font-size: 1.75rem;
		font-weight: 600;
	}

	h4 {
		font-size: 1.5rem;
		font-weight: 600;
	}

	h5 {
		font-size: 1.25rem;
		font-weight: 600;
	}

	ol,
	ul {
		list-style-position: inside;
	}

	ol {
		list-style-type: decimal;
	}

	ul {
		list-style-type: disc;
	}

	ul.override-list-style>li {
		@apply relative list-none;
	}

	ul.override-list-style>li::before {
		content: "";
		@apply absolute rounded-full w-1.5 h-1.5 bg-gray-700 -left-4 top-2.5;
	}

	blockquote {
		background: #f9f9f9;
		border-left: 10px solid #ccc;
		margin: 1.5em 10px;
		padding: 0.5em 10px;
		quotes: "\201C" "\201D" "\2018" "\2019";
	}

	blockquote:before {
		color: #ccc;
		content: open-quote;
		font-size: 4em;
		line-height: 0.1em;
		margin-right: 0.25em;
		vertical-align: -0.4em;
	}

	blockquote p {
		display: inline;
	}


	a[href=""],
	a[href="#"],
	a:not([href]) {
		@apply no-underline text-inherit;
	}
}

@layer utilities {

	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
		width: 0px;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
	}

	.hidden-actions[data-state="open"] {
		opacity: 1;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-track {
		@apply bg-transparent;
	}

	::-webkit-scrollbar-thumb {
		@apply bg-gray-500 rounded-lg;
	}

	::-webkit-scrollbar-thumb:hover {
		@apply bg-gray-600;
	}

	/* TODO: Fix this as it breaks builds when using preset */
	/* .accordion-margin-transition {
	  @apply transition-[margin] duration-300 ease-[cubic-bezier(0.87,0,0.13,1)];
	} */

	.col-tree:last-child .bottom-half-dash {
		@apply border-none;
	}
}

.scrolling-touch {
	-webkit-overflow-scrolling: touch;
}

.scrolling-auto {
	-webkit-overflow-scrolling: auto;
}

/* Classes to remove number spinners from inputs of type number */
/* Chrome, Safari, Edge, Opera */
.remove-number-spinner::-webkit-outer-spin-button,
.remove-number-spinner::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.remove-number-spinner {
	-moz-appearance: textfield;
}

.tiptap>*+* {
	margin-top: 0.75em;
}

.tiptap.ProseMirror-focused {
	outline: none;
}

.tiptap ul,
.tiptap ol {
	padding: 0 1rem;
}

.tiptap li>p {
	display: inline;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
	line-height: 1.1;
}

.tiptap code {
	background-color: rgba(97, 97, 97, 0.1);
	color: #616161;
}

.tiptap pre {
	background: #0d0d0d;
	color: #fff;
	font-family: "JetBrainsMono", monospace;
	padding: 0.75rem 1rem;
	border-radius: 0.5rem;
}

.tiptap pre code {
	color: inherit;
	padding: 0;
	background: none;
	font-size: 0.8rem;
}

.tiptap img {
	max-width: 100%;
	height: auto;
}

.tiptap blockquote {
	padding-left: 1rem;
	border-left: 2px solid rgba(13, 13, 13, 0.1);
}

.tiptap hr {
	border: none;
	border-top: 2px solid rgba(13, 13, 13, 0.1);
	margin: 2rem 0;
}

.css-qbdosj-Input {
	margin: 0px !important;
	padding: 0px !important;
}

.css-1h01tm3-Input {
	margin: 0px !important;
	padding: 0px !important;
}

.css-166bipr-Input {
	margin: 0px !important;
	padding: 0px !important;
}